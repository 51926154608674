
import { ref } from 'vue';
import { api } from '@/api/useAxios'
import { getCompanyId } from '@/utils/auth';

interface ComP {
  id: number,
  companyName: string
}

export default {
    name: '',
      setup() {
        const companyId = ref()
        const companylist = ref<ComP[]>([])
        const show = ref(false)
        const { VUE_APP_COMPANY_ID, VUE_APP_COMPANY_NAME } = process.env
        const companyName = ref('')

        const fetch = () => {
          api.get('/companyinfo/qryH5UserCompany').then((r) => {
            const dayObject: any = {}
              dayObject['id'] = 999
              dayObject['companyName'] = '魔脉，企业传帮带数字管家'
              companylist.value.push(dayObject)
            if(r){
              companylist.value.push(...r)
            }
            if(getCompanyId()){
              companyId.value = Number(getCompanyId())
              resetName(companyId.value)
            }
          })
        }
        fetch()

        const resetName = (str:any) => {
          const compinfo = companylist.value.find(item => item.id === str)
          if(compinfo){
            companyName.value = Object(compinfo).companyName
            window.sessionStorage.setItem(VUE_APP_COMPANY_NAME, companyName.value)
          }
        }
        
        return {
          companyName,
          show,
          openpopup: () => {
            show.value = true
          },
          submit: (id: any) => {
              companyId.value = id
              window.sessionStorage.setItem(VUE_APP_COMPANY_ID, id)
              resetName(id)
              show.value = false
          },
          companyId,
          companylist
        }

      }
  };
